import 'regenerator-runtime/runtime.js'
import Q$ from './Query.js'
import './notifier.js'
import './dt-polyfill.js'
import { Route } from './code-router.js'
import { Locale } from '@neumatter/intl'
import { TimeZone } from '@neumatter/datetime'

class Accesibility {
  static systemDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
  static reduceMotion = window.matchMedia('(prefers-reduced-motion: reduce)')
  static reduceTransparency = window.matchMedia('(prefers-reduced-transparency: reduce)')

  static animate (callback, stop) {
    if (Accesibility.reduceMotion.matches) {
      stop()
    } else {
      callback()
    }

    Accesibility.reduceMotion.addEventListener('change', event => {
      if (event.matches) {
        stop()
      } else {
        callback()
      }
    })
  }
}

class MultiSet {
  constructor (id) {
    this.mobile = document.querySelector(id)
    this.desktop = document.querySelector(id + '-desktop')
    this.tablet = document.querySelector(id + '-tablet')
  }

  get textContent () {
    return this.mobile.textContent
  }

  set textContent (value) {
    this.mobile.textContent = value
    this.desktop.textContent = value
    this.tablet.textContent = value
  }
}

Route('/ols2024', () => {
  const timeOfEvent = globalThis.DateTime.from('2024-09-06T18:30:00.000000-05:00[America/Chicago]')
  console.info('Time of event: ', timeOfEvent.toString())
  const now = globalThis.DateTime.now({ timeZone: 'America/Chicago' })
  console.info('Now: ', now.toString())
  const timeUntilEvent = now.until(timeOfEvent, { largestUnit: 'day', smallestUnit: 'second' })
  console.info('Time until event: ', timeUntilEvent.toString())
  const days = timeUntilEvent.days
  const hours = timeUntilEvent.hours
  const minutes = timeUntilEvent.minutes
  const seconds = timeUntilEvent.seconds

  const countdownDays = new MultiSet('#days')
  const countdownHours = new MultiSet('#hours')
  const countdownMinutes = new MultiSet('#minutes')
  const countdownSeconds = new MultiSet('#seconds')

  countdownDays.textContent = days
  countdownHours.textContent = `00${hours}`.slice(-2)
  countdownMinutes.textContent = `00${minutes}`.slice(-2)
  countdownSeconds.textContent = `00${seconds}`.slice(-2)

  const interval = setInterval(() => {
    const now = globalThis.DateTime.now()
    const timeUntilEvent = now.until(timeOfEvent, { largestUnit: 'day', smallestUnit: 'second' })
    const days = timeUntilEvent.days
    const hours = timeUntilEvent.hours
    const minutes = timeUntilEvent.minutes
    const seconds = timeUntilEvent.seconds

    countdownDays.textContent = days
    countdownHours.textContent = `00${hours}`.slice(-2)
    countdownMinutes.textContent = `00${minutes}`.slice(-2)
    countdownSeconds.textContent = `00${seconds}`.slice(-2)
  }, 1000)

  return () => clearInterval(interval)
})


// const dropdownButtons = document.querySelectorAll('[data-toggle=dropdown]')

class BaseElement {
  /**
   * 
   * @param {string} selector 
   * @returns {AllCursor}
   */
  static all (selector) {
    return new AllCursor(selector)
  }

  /**
   *
   * @param {string | Element} input
   * @returns {Promise<this>}
   */
  static find (input) {
    return new Promise((resolve, reject) => {
      if (typeof input === 'string') {
        const element = document.querySelector(input)
        if (element) {
          resolve(new BaseElement(element))
        } else {
          const max = 100
          let count = 0

          const interval = setInterval(() => {
            count++
            const element = document.querySelector(input)
            if (element || count >= max) {
              clearInterval(interval)
              if (element) {
                resolve(new BaseElement(element))
              } else {
                reject(new Error('Element not found'))
              }
            }
          }, 100)
        }
      } else if (input instanceof Element) {
        resolve(new BaseElement(input))
      } else {
        reject(new Error('Invalid input'))
      }
    })
  }

  /**
   *
   * @param {Element} element
   */
  constructor (element) {
    /** @type {Element} */
    this.element = element
  }

  /**
   * @param {string} selector
   * @returns {Promise<BaseElement>}
   */
  find (selector) {
    return new Promise((resolve, reject) => {
      const element = this.element.querySelector(selector)

      if (element) {
        resolve(new BaseElement(element))
      } else {
        const max = 100
        let count = 0

        const interval = setInterval(() => {
          count++
          const element = this.element.querySelector(selector)
          if (element || count >= max) {
            clearInterval(interval)
            if (element) {
              resolve(new BaseElement(element))
            } else {
              reject(new Error('Element not found'))
            }
          }
        }, 100)
      }
    })
  }
}

document.querySelectorAll('.js-hover').forEach(element => {
  const animation = element.animate([
    { transform: 'translateY(5px)' },
    { transform: 'translateY(0)' },
  ], {
    duration: 1000,
    easing: 'ease-in-out',
    direction: 'alternate',
    iterations: Infinity,
  })

  Accesibility.animate(() => {
    switch (animation.playState) {
      case 'running':
        break
      case 'paused':
        animation.play()
        break
      case 'finished':
        break
      case 'idle':
        animation.play()
        break
      default:
        break;
    }
  }, () => {
    animation.pause()
  })
})


class AllCursor {
  /**
   *
   * @param {string} selector
   * @param {HTMLElement} element
   * @returns {AllCursor}
   */
  constructor (selector, element = document) {
    /** @type {Array<Promise<BaseElement>>} */
    this.elements = Array.from(element.querySelectorAll(selector)).map(element => BaseElement.find(element))
  }

  /**
   * 
   * @param {(element: BaseElement) => void} callback
   * @returns {void}
   */
  forEach (callback) {
    this.elements.forEach(element => element.then(callback).catch(e => {}))
  }
}

class Dropdown extends BaseElement {
  /**
   *
   * @param {BaseElement} dropdown
   */
  constructor (dropdown) {
    super(dropdown.element)
    dropdown.find('[data-toggle=dropdown]').then(button => {
      /** @type {Element} */
      this.button = button.element
      this.button.addEventListener('click', this.toggle.bind(this))
    })

    dropdown.find('.dropdown-menu').then(menu => {
      /** @type {Element} */
      this.menu = menu.element
      document.addEventListener('click', this.close.bind(this))
    })

    /** @type {boolean} */
    this.isOpen = false
    /** @type {boolean} */
    this.isClicked = false
  }

  toggle (event) {
    // event.stopPropagation()
    this.isOpen = !this.isOpen
    this.isClicked = !this.isClicked
    this.menu.style.display = this.isOpen ? 'block' : 'none'

    if (this.isOpen) {
      this.easeIn(this.menu, 200)
    }
  }

  close (event) {
    if (this.isClicked) {
      this.isClicked = false
      return
    }

    this.isOpen = false
    this.menu.style.display = 'none'
  }

  easeIn (element, duration) {
    const start = performance.now()
    const from = 0
    const to = 1
    const ease = t => {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    }

    const height = element.getBoundingClientRect().height
    element.style.height = '0px'
    element.style.overflow = 'hidden'

    const step = () => {
      const time = performance.now()
      const elapsed = time - start
      const progress = elapsed / duration
      const value = from + (to - from) * ease(progress)

      element.style.height = `${Math.min(height * value, height)}px`

      if (value > 0.99) {
        element.style.overflow = 'initial'
      }

      if (progress < 1) {
        requestAnimationFrame(step)
      }
    }
    step()
  }
}

BaseElement.all('.dropdown').forEach(dropdown => new Dropdown(dropdown))
/*
dropdownButtons.forEach(dropdownButton => {
  dropdownButton.addEventListener('click', event => {
    const target = event.target
    const dropdown = target.closest('.dropdown')
    const dropdownMenu = dropdown.querySelector('.dropdown-menu')

    if (dropdownMenu.style.display === 'block') {
      dropdownMenu.style.display = 'none'
    } else {
      dropdownMenu.style.display = 'block'
    }
  })
})

document.addEventListener('click', event => {
  dropdownButtons.forEach(dropdownButton => {
    const dropdownMenu = dropdown.querySelector('.dropdown-menu')
    const bounds = dropdownMenu.getBoundingClientRect()

    if (event.x < bounds.left || event.x > bounds.right) {
      dropdownMenu.style.display = 'none'
    } else if (event.y < bounds.top || event.y > bounds.bottom) {
      dropdownMenu.style.display = 'none'
    }
  })
})
*/

const Win = Q$('window')
const DocEl = Q$('docelement')

const themeBtn = Q$('#theme-btn-lg')
const themeBtnLabel = Q$('[data-btn=theme]')
const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
const darkTheme = Q$('[data-color=dark-theme]')
const lightTheme = Q$('[data-color=light-theme]')
const switchNav = Q$('#nmNavbar')
const navBrand = Q$('#navbrand')
const navLink = Q$('[data-switch=linkClass]')
const navBtn = Q$('[data-switch=btnClass]')

const { localStorage } = window

const Theme = {
  dark: () => {
    DocEl.setAttr('data-theme', 'dark')
    themeBtnLabel.swapClass('theme-btn-forlight', 'theme-btn')
    themeBtn.check()
  },
  light: () => {
    DocEl.setAttr('data-theme', 'light')
    themeBtnLabel.swapClass('theme-btn', 'theme-btn-forlight')
    themeBtn.uncheck()
  },
  colorDown: () => {
    if (window.localStorage.getItem('theme') === 'dark') {
      darkTheme.setAttr('content', '#444447')
      lightTheme.setAttr('content', '#444447')
    } else {
      darkTheme.setAttr('content', '#404042')
      lightTheme.setAttr('content', '#404042')
    }
  },
  colorUp: () => {
    if (prefersDarkMode.matches) {
      darkTheme.setAttr('content', '#2342d3')
      lightTheme.setAttr('content', '#2342d3')
    } else {
      darkTheme.setAttr('content', '#fafafa')
      lightTheme.setAttr('content', '#fafafa')
    }
  }
}

const Nav = {
  down: () => {
    switchNav.swapClass('bg-white-switch', 'bg-blur-switch')
    navBrand.swapClass('nav-brand-color', 'nav-brand-white')
    navLink.swapClass('nav-link-switch', 'nav-link')
    navBtn.swapClass('btn-nav-switch', 'btn-nav')
  },
  up: () => {
    switchNav.swapClass('bg-blur-switch', 'bg-white-switch')
    navBrand.swapClass('nav-brand-white', 'nav-brand-color')
    navLink.swapClass('nav-link', 'nav-link-switch')
    navBtn.swapClass('btn-nav', 'btn-nav-switch')
  }
}

const Locator = {
  update: () => {
    const winWidth = Win.innerWidth()
    if (winWidth <= 1200) {
      Q$('#CollapseNav').removeClass('show')
      Q$('#CollapseNavSecondary').removeClass('show')
      Q$('#locator-1').removeClass('a-border')
      Q$('#NavLocator')
        .swapClass('icon-locator-open', 'icon-locator-closed')
        .swapClass('fixed-top-locator-toggle-2', 'fixed-top-locator-toggle')
    } else if (winWidth >= 1200) {
      if (Q$('#CollapseNavSecondary').hasClass('show')) {
        Q$('#NavLocator')
          .swapClass('fixed-top-locator-toggle-2', 'fixed-top-locator-toggle')
          .swapClass('icon-locator-closed', 'icon-locator-open')
        Q$('#locator-1').addClass('a-border')
      } else {
        Q$('#NavLocator')
          .swapClass('fixed-top-locator-toggle', 'fixed-top-locator-toggle-2')
          .swapClass('icon-locator-open', 'icon-locator-closed')
        Q$('#locator-1').removeClass('a-border')
      }
    }
  },
  click: () => {
    const winWidth = Win.innerWidth()
    const closeSmallLocatorIcon = () =>
      Q$('#NavLocator')
        .swapClass('icon-5', 'icon-4')
        .swapClass('fixed-top-locator-toggle-2', 'fixed-top-locator-toggle')
    const openSmallLocatorIcon = () =>
      Q$('#NavLocator').swapClass('icon-4', 'icon-5')
    const closeLargeLocatorIcon = () =>
      Q$('#NavLocator')
        .swapClass('icon-5', 'icon-6')
        .swapClass('fixed-top-locator-toggle', 'fixed-top-locator-toggle-2')
    const openLargeLocatorIcon = () =>
      Q$('#NavLocator')
        .swapClass('icon-6', 'icon-5')
        .swapClass('fixed-top-locator-toggle-2', 'fixed-top-locator-toggle')
    if (Q$('#NavLocator').hasClass('icon-locator-open')) {
      Q$('#NavLocator').swapClass('icon-locator-open', 'icon-locator-closed')
      Q$('#locator-1').removeClass('a-border')
      ;(winWidth <= 1200 ? closeSmallLocatorIcon : closeLargeLocatorIcon)()
    } else if (Q$('#NavLocator').hasClass('icon-locator-closed')) {
      Q$('#NavLocator').swapClass('icon-locator-closed', 'icon-locator-open')
      Q$('#locator-1').addClass('a-border')
      ;(winWidth <= 1200 ? openSmallLocatorIcon : openLargeLocatorIcon)()
    }
  }
}

const updateVideo = () => {
  const homeVideo = document.querySelector('#header-video')
    ? document.querySelector('#header-video')
    : ''
  if (homeVideo !== '') {
    setTimeout(() => {
      homeVideo.load()
    }, 100)
  }
}

Win.eventOn('load', () => {
  Locator.update()
  Q$('p').textEmpty()
  if (!localStorage.getItem('theme')) {
    // set theme on load
    if (prefersDarkMode.matches) {
      Theme.dark()
    } else {
      Theme.light()
    }
  } else {
    if (localStorage.getItem('theme') === 'dark') {
      Theme.dark()
    } else {
      if (localStorage.getItem('theme') === 'light') {
        Theme.light()
      }
    }
  }
})

Win.eventOn('scroll', () => {
  // theme scroll listener
  const scroll = document.documentElement.scrollTop
  if (scroll >= 100) {
    Theme.colorDown()
    Nav.down()
  } else {
    Theme.colorUp()
    Nav.up()
  }
})

Win.eventOn('resize', () => {
  Locator.update()
})

Q$('#NavLocator').eventOn('click', () => {
  Locator.click()
})

themeBtn.eventOn('click', () => {
  // theme btn listener
  const updateTheme = window.localStorage.getItem('theme')
  if (updateTheme === 'dark') {
    localStorage.setItem('theme', 'light')
    Theme.light()
  } else {
    if (updateTheme === 'light') {
      localStorage.setItem('theme', 'dark')
      Theme.dark()
    }
  }
  if (updateTheme === null) {
    if (prefersDarkMode.matches) {
      localStorage.setItem('theme', 'light')
      Theme.light()
    } else {
      localStorage.setItem('theme', 'dark')
      Theme.dark()
    }
  }
})

const root = document.querySelector(':root')
/*
root.style.fontSize = '16' + 'px'
const textUp = document.querySelector('#textUp')
const textDown = document.querySelector('#textDown')
let setText = localStorage.getItem('typeValue')
if (!setText) {
  root.style.fontSize = '16' + 'px'
} else {
  root.style.fontSize = setText + 'px'
}
console.log(root)
textUp.addEventListener('click', () => {
  let size = parseInt(root.style.fontSize.match(/\d/))
  console.log(size)
  console.log(root.style.fontSize)
  const newSize = size < 25 ? size += 1 : size
  root.style.fontSize = String(newSize) + 'px'
  localStorage.setItem('typeValue', String(newSize))
})
textDown.addEventListener('click', () => {
  let size = parseInt(root.style.fontSize.match(/\d/))
  console.log(size)
  console.log(root.style.fontSize)
  const newSize = size > 10 ? size -= 1 : size
  root.style.fontSize = String(newSize) + 'px'
  localStorage.setItem('typeValue', String(newSize))
})
*/

const typeSlider = document.querySelector('#slider')

if (typeSlider) {
  typeSlider.min = 0.8
  typeSlider.max = 1.15
  const setV = localStorage.getItem('typeValue')
  if (!setV) {
    typeSlider.value = 1
    root.style.fontSize = '1' + 'rem'
  } else {
    typeSlider.value = setV
    root.style.fontSize = setV + 'rem'
  }
  typeSlider.addEventListener('input', () => {
    // type range, sets type size on input
    const typeValue = typeSlider.value
    root.style.fontSize = typeValue + 'rem'
    localStorage.setItem('typeValue', typeValue)
  })
}

const searchBar = document.querySelector('[data-search=input]')
if (searchBar) {
  searchBar.addEventListener('keyup', event => {
    const searchString = event.target.value.toLowerCase()
    const products = document.querySelectorAll('[data-search=product]')
    const { length } = products
    let index = -1

    while (++index < length) {
      const searchField = products[index].querySelector('#hidden-search-card')
      const textContent = searchField
        ? searchField.textContent.replace(/[®™]/g, '')
        : products[index].textContent.toLowerCase().replace(/\n\r?/g, ' - ').replace(/[®™]/g, '')
      if (!searchString || textContent.indexOf(searchString) > -1) {
        products[index].style.display = 'initial'
      } else {
        products[index].style.display = 'none'
      }
    }
  })
}

/*
Q$('[data-search=input]').eventOn('keyup', e => {
  // search on keypress
  const searchString = Q$(e).searchVal()
  const productName = document.querySelectorAll('[data-search=productName]')
  const product = document.querySelectorAll('[data-search=product]')
  for (let i = 0; i < product.length; i++) {
    if (
      !searchString ||
      productName[i].textContent.toLowerCase().indexOf(searchString) > -1
    ) {
      Q$(product[i]).show()
    } else {
      Q$(product[i]).hide()
    }
  }
})
*/

Q$('.backToBrowsing').eventOn('click', e => {
  e.preventDefault()
  if (!document.referrer.includes('numedica')) {
    Win.locationNM()
  } else {
    Win.back()
  }
})

updateVideo()

///
// PRIVATE LABEL PACKETING FORM
//
let _previousFormula = 0
let _addFormulaProgress = 1
let _formProgress = 1
const animationInterval = 60
let _formulaCount = 0
const formulaAmounts = {
  one: 0,
  two: 0,
  three: 0,
  four: 0,
  five: 0,
  six: 0,
  seven: 0,
  eight: 0
}

const initPacketing = () => {
  if (Win.location().match(/packet-request/g)) {
    if (Q$('#collapsed_1').hasClass('d-none')) {
      _formProgress = 2
      console.log('has all user fields on init')
    } else {
      _formProgress = 1
      Q$('#backBtn').addClass('disabled')
      Q$('#backBtn').disable()
    }
    checkDisable()
  }
}

// Alters min based on 4 or 8 week
Q$('#packet_week_supply').eventOn('change', () => {
  let selected = Q$('#packet_week_supply').selectedOption()
  if (selected === 0) {
    Q$('#packet_box_count')
      .min('72')
      .value('72')
  } else {
    Q$('#packet_box_count')
      .min('48')
      .value('48')
  }
})

Q$('#plYes').eventOn('click', () => {
  Q$('#plYes').hide()
  Q$('#plNo').hide()
})

Q$('#plNo').eventOn('click', () => {
  Q$('#plYes').hide()
  Q$('#plNo').hide()
  Q$('#downloadPLSheet').removeClass('d-none')
})

/// EVALUATES QUANTITY
const evaluateQuantities = (quantityInput, removeFormula) => {
  let thisIsSelected = Q$(quantityInput).selectedOption()
  thisIsSelected += removeFormula ? 0 : 1
  if (_addFormulaProgress === 1) formulaAmounts.one = thisIsSelected
  else if (_addFormulaProgress === 2) formulaAmounts.two = thisIsSelected
  else if (_addFormulaProgress === 3) formulaAmounts.three = thisIsSelected
  else if (_addFormulaProgress === 4) formulaAmounts.four = thisIsSelected
  else if (_addFormulaProgress === 5) formulaAmounts.five = thisIsSelected
  else if (_addFormulaProgress === 6) formulaAmounts.six = thisIsSelected
  else if (_addFormulaProgress === 7) formulaAmounts.seven = thisIsSelected
  else if (_addFormulaProgress === 8) formulaAmounts.eight = thisIsSelected
  _formulaCount =
    formulaAmounts.one +
    formulaAmounts.two +
    formulaAmounts.three +
    formulaAmounts.four +
    formulaAmounts.five +
    formulaAmounts.six +
    formulaAmounts.seven +
    formulaAmounts.eight
  console.log(_formulaCount)
  if (_formulaCount === 8)
    Q$('#addNewFormula')
      .addClass('disabled')
      .disable()
  else
    Q$('#addNewFormula')
      .removeClass('disabled')
      .able()
}

/// RETURNS SELECTORS that need to be initialized by formulaProgress
const returnPrivateLabel = (_add, _previous, ev) => {
  const formula = {
    append: `#newFormula_${_add}`,
    suggestions: `#PL_searchBox_${_add}`,
    quantity: `[name=private_label_quantity_${_add}]`,
    previousQuantity: _previous
      ? `[name=private_label_quantity_${_previous}]`
      : '',
    previousInput: _previous ? `#numedica_formula_${_previous}` : '',
    activeInput: `#numedica_formula_${_add}`,
    activeInputValue: Q$(`#numedica_formula_${_add}`).value(),
    products: '[data-search=productNamePL]',
    searchString: ev ? Q$(ev).searchVal() : ''
  }
  return formula
}

/// FOR INITIAL LOAD
Q$(`[name=private_label_quantity_${_addFormulaProgress}]`).eventOn(
  'change',
  () => {
    const formula = returnPrivateLabel(_addFormulaProgress)
    evaluateQuantities(formula.quantity, false)
  }
)

/// ADD FORMULA
Q$('#addNewFormula').eventOn('click', () => {
  _addFormulaProgress += 1
  _previousFormula = _addFormulaProgress - 1
  const formula = returnPrivateLabel(_addFormulaProgress, _previousFormula)
  Q$(formula.quantity).eventOn('change', () => {
    evaluateQuantities(formula.quantity, false)
  })
  Q$(formula.append).removeClass('d-none')
  Q$(formula.previousQuantity)
    .addClass('disabled')
    .disable()
  Q$(formula.previousInput)
    .addClass('disabled')
    .disable()
  Q$(formula.quantity).removeOption(0)
  evaluateQuantities(formula.quantity, false)
  if (_formulaCount >= 8)
    Q$(formula.quantity)
      .removeOption([7])
      .removeOption([6])
      .removeOption([5])
      .removeOption([4])
      .removeOption([3])
      .removeOption([2])
      .removeOption([1])
  else if (_formulaCount >= 7)
    Q$(formula.quantity)
      .removeOption([7])
      .removeOption([6])
      .removeOption([5])
      .removeOption([4])
      .removeOption([3])
      .removeOption([2])
  else if (_formulaCount >= 6)
    Q$(formula.quantity)
      .removeOption([7])
      .removeOption([6])
      .removeOption([5])
      .removeOption([4])
      .removeOption([3])
  else if (_formulaCount >= 5)
    Q$(formula.quantity)
      .removeOption([7])
      .removeOption([6])
      .removeOption([5])
      .removeOption([4])
  else if (_formulaCount >= 4)
    Q$(formula.quantity)
      .removeOption([7])
      .removeOption([6])
      .removeOption([5])
  else if (_formulaCount >= 3)
    Q$(formula.quantity)
      .removeOption([7])
      .removeOption([6])
})

/// REMOVE FORMULA
Q$('#removeNewFormula').eventOn('click', () => {
  _previousFormula = _addFormulaProgress - 1
  const formula = returnPrivateLabel(_addFormulaProgress, _previousFormula)
  Q$(formula.quantity).eventOn('change', () => {
    evaluateQuantities(formula.quantity, false)
  })
  Q$(formula.previousQuantity)
    .removeClass('disabled')
    .able()
  Q$(formula.previousInput)
    .removeClass('disabled')
    .able()
  Q$(formula.append).addClass('d-none')
  const createOption = numb => {
    let newOption = document.createElement('option')
    newOption.text = `${numb}`
    newOption.value = numb
    return newOption
  }
  Q$(formula.quantity)
    .getOptions()
    .forEach(opt => {
      opt.remove()
    })
  Q$(formula.quantity)
    .addOption(createOption(0), 0)
    .addOption(createOption(1), 1)
    .addOption(createOption(2), 2)
    .addOption(createOption(3), 3)
    .addOption(createOption(4), 4)
    .addOption(createOption(5), 5)
    .addOption(createOption(6), 6)
    .addOption(createOption(7), 7)
    .addOption(createOption(8), 8)
  evaluateQuantities(formula.quantity, true)
  _addFormulaProgress -= 1
})

/// ADD LISTENER initialized by the keyup function
const addSearchListener = (searchBox, activeInput) => {
  Q$(searchBox).eventOn('click', event => {
    const text = event.target.textContent
    Q$(activeInput).value(text)
    Q$(searchBox).hide()
    Q$('#nextBtn')
      .removeClass('disabled')
      .able()
  })
}

/// SUGGESTIONS keyup search
Q$('[data-search=PL]').eventOn('keyup', event => {
  let formula = returnPrivateLabel(_addFormulaProgress, '', event)
  Q$(formula.suggestions).show()
  Q$(formula.products).each(product => {
    if (
      !formula.searchString ||
      product.textContent.toLowerCase().indexOf(formula.searchString) > -1
    )
      Q$(product).show()
    else Q$(product).hide()
  })
  addSearchListener(formula.suggestions, formula.activeInput)
  return setTimeout(() => {
    evaluateQuantities(formula.quantity, false)
    if (document.querySelector('[data-search=PL]') !== document.activeElement) {
      console.log(':focus=false')
      Q$(formula.suggestions).hide()
      if (formula.activeInputValue === '' || _formulaCount === 0)
        Q$('#nextBtn')
          .addClass('disabled')
          .disable()
      else
        Q$('#nextBtn')
          .removeClass('disabled')
          .able()
    } else {
      console.log(':focus=true')
    }
  }, 7000)
})

//// DISABLE CHECK checks to see if buttons should be disabled
const checkDisable = () => {
  if (_formProgress === 5)
    Q$('#nextBtn')
      .addClass('disabled')
      .disable()
  else if (_formProgress === 3 && _formulaCount === 0)
    Q$('#nextBtn')
      .addClass('disabled')
      .disable()
  else
    Q$('#nextBtn')
      .removeClass('disabled')
      .able()

  if (_formProgress === 1)
    Q$('#backBtn')
      .addClass('disabled')
      .disable()
  else
    Q$('#backBtn')
      .removeClass('disabled')
      .able()
}

// INIT test for packeting page
initPacketing()

/// NEXT FORM
Q$('#nextBtn').eventOn('click', () => {
  let xAppendPage = 1200
  _formProgress += 1
  let previousPage = _formProgress - 1
  let appendPage = `#collapsed_${_formProgress}`
  let removePage = `#collapsed_${previousPage}`
  Q$(appendPage).removeClass('d-none')
  Q$(removePage).addClass('d-none')
  checkDisable()
  const animateNext = () => {
    xAppendPage -= animationInterval
    Q$(appendPage).transform(`translate3d(${xAppendPage}px, 0, 0)`)
    if (Math.abs(xAppendPage) === 0) {
      xAppendPage = 0
      return
    }
    window.requestAnimationFrame(animateNext)
  }
  animateNext()
})

/// BACK FORM
Q$('#backBtn').eventOn('click', () => {
  let xAppendPage = -1200
  _formProgress -= 1
  let previousPage = _formProgress + 1
  let appendPage = Q$(`#collapsed_${_formProgress}`)
  let removePage = Q$(`#collapsed_${previousPage}`)
  removePage.addClass('d-none')
  appendPage.removeClass('d-none')
  checkDisable()
  const animateBack = () => {
    xAppendPage += animationInterval
    appendPage.transform(`translate3d(${xAppendPage}px, 0, 0)`)
    if (Math.abs(xAppendPage) === 0) {
      xAppendPage = 0
      return
    }
    window.requestAnimationFrame(animateBack)
  }
  animateBack()
})

class DeviceInfo {
  options = []

  static operatingSystems = [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
  ]

  static browsers = [
    { name: 'Edge', value: 'EdgiOS', version: 'EdgiOS' },
    { name: 'Edge', value: 'EdgA', version: 'EdgA' },
    { name: 'Edge', value: 'Edge', version: 'Edge' },
    { name: 'Edge', value: 'Edge', version: 'Edge' },
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
  ]

  constructor () {
    const userAgent = navigator.userAgent
    this.os = DeviceInfo.matchItem(userAgent, DeviceInfo.operatingSystems)
    this.browser = DeviceInfo.matchItem(userAgent, DeviceInfo.browsers)
    this.browser.releaseDate = DeviceInfo.getBrowserYear(this.browser)
    this.resolution = `${window.screen.width}x${window.screen.height}`
  }

  static getBrowserYear (browser) {
    let year = '0000'
    switch (browser.name) {
      case 'Chrome':
        if (browser.version <= 8) {
          year = '2010'
        } else if (browser.version <= 16) {
          year = '2011'
        } else if (browser.version <= 23) {
          year = '2012'
        } else if (browser.version <= 31) {
          year = '2013'
        } else if (browser.version <= 39) {
          year = '2014'
        } else if (browser.version <= 47) {
          year = '2015'
        } else if (browser.version <= 55) {
          year = '2016'
        } else if (browser.version <= 63) {
          year = '2017'
        } else if (browser.version <= 71) {
          year = '2018'
        } else if (browser.version <= 79) {
          year = '2019'
        } else if (browser.version <= 87) {
          year = '2020'
        } else if (browser.version <= 96) {
          year = '2021'
        } else {
          year = '2022'
        }
        break
      case 'Safari':
        if (browser.version <= 5) {
          year = '2010'
        } else if (browser.version < 6.5) {
          year = '2011'
        } else if (browser.version <= 6) {
          year = '2012'
        } else if (browser.version <= 7) {
          year = '2013'
        } else if (browser.version <= 8) {
          year = '2014'
        } else if (browser.version <= 9) {
          year = '2015'
        } else if (browser.version <= 10) {
          year = '2016'
        } else if (browser.version <= 11) {
          year = '2017'
        } else if (browser.version <= 12) {
          year = '2018'
        } else if (browser.version <= 13) {
          year = '2019'
        } else if (browser.version < 15) {
          year = '2020'
        } else if (browser.version < 15.4) {
          year = '2021'
        } else {
          year = '2022'
        }
        break
      case 'Firefox':
        if (browser.version <= 4) {
          year = '2010'
        } else if (browser.version <= 9) {
          year = '2011'
        } else if (browser.version <= 17) {
          year = '2012'
        } else if (browser.version <= 26) {
          year = '2013'
        } else if (browser.version <= 34) {
          year = '2014'
        } else if (browser.version <= 43) {
          year = '2015'
        } else if (browser.version <= 50) {
          year = '2016'
        } else if (browser.version <= 57) {
          year = '2017'
        } else if (browser.version <= 64) {
          year = '2018'
        } else if (browser.version <= 71) {
          year = '2019'
        } else if (browser.version <= 84) {
          year = '2020'
        } else if (browser.version <= 95) {
          year = '2021'
        } else {
          year = '2022'
        }
        break
      case 'Edge':
        if (browser.version <= 26) {
          year = '2015'
        } else if (browser.version <= 39) {
          year = '2016'
        } else if (browser.version <= 42) {
          year = '2017'
        } else if (browser.version <= 44.1) {
          year = '2018'
        } else if (browser.version <= 78) {
          year = '2019'
        } else if (browser.version <= 87) {
          year = '2020'
        } else if (browser.version <= 96) {
          year = '2021'
        } else {
          year = '2022'
        }
        break
      default:
        year = '0000'
        break
    }

    return year
  }

  static matchItem (string, data) {
    try {
      let i = 0
      let j = 0
      let regex
      let regexv
      let match
      let matches
      let version

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i')
        match = regex.test(string)
        if (match) {
          regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i')
          matches = string.match(regexv)
          version = ''
          if (matches) {
            if (matches[1]) {
              matches = matches[1]
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/)
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + '.'
              } else {
                version += matches[j]
              }
            }
          } else {
            version = '0'
          }
          return {
            name: data[i].name,
            version: parseFloat(version)
          }
        }
      }
      return { name: 'unknown', version: 0 }
    } catch (err) {
      console.error(err)
      return { name: 'unknown', version: 0 }
    }
  }
}

class LocalStore {
  #data = {}

  constructor () {
    const length = localStorage.length
    let index = -1

    while (++index < length) {
      const key = localStorage.key(index)
      this.#data[key] = getLocalStoreItem(localStorage.getItem(key))
    }
  }

  get (key) {
    return typeof this.#data[key] === 'undefined' ? null : this.#data[key]
  }

  use (key, defaultValue) {
    return new Promise((resolve, reject) => {
      if (typeof this.#data[key] === 'undefined') {
        if (typeof defaultValue === 'function') {
          const defaultValueResult = defaultValue()
          if (typeof defaultValueResult === 'object' && typeof defaultValueResult.then === 'function') {
            defaultValueResult
              .then(result => {
                this.set(key, result)
                resolve(result)
              })
              .catch(reject)
          } else {
            this.set(key, defaultValueResult)
            resolve(defaultValueResult)
          }
        } else {
          this.set(key, defaultValue)
          resolve(defaultValue)
        }
      } else {
        resolve(this.#data[key])
      }
    })
  }

  set (key, value) {
    this.#data[key] = value
    localStorage.setItem(key, JSON.stringify(value))
  }
}

const navbarSearchInput = document.querySelector('#navbarSearch')
const navbarSearchSuggestions = document.querySelector('#navbarSearchSuggestions')

if (navbarSearchInput && navbarSearchSuggestions) {
  const localStore = new LocalStore()

  localStore
    .use('products', async () => {
      const response = await fetch('https://numedica.com/formulas')
      const text = await response.text()
      const matches = text.match(/<p class="t-size-h3 formula-card-name" data-search="productName">([^<]+)<\/p>/g)
      return matches.map(string => {
        return string.replace(/<p class="t-size-h3 formula-card-name" data-search="productName">([^<]+)<\/p>/, '$1')
      })
    })
    .then(products => {
      let filteredProducts = []
      navbarSearchInput.addEventListener('keyup', (event) => {
        const input = event.target.value
        if (input === '') {
          filteredProducts = []
        } else {
          filteredProducts = products.filter(name => {
            return name.toLowerCase().indexOf(input.toLowerCase()) !== -1
          })
        }
        console.log(filteredProducts)
        const previousUL = document.querySelector('#searchSuggestionsUL')
        if (previousUL) {
          previousUL.remove()
        }
        const ul = document.createElement('ul')
        ul.style = 'position: fixed; background-color: rgb(126, 70, 255); color: #292929; list-style-type: none;'
        // ul.className = 'dropdown-menu show'
        ul.id = 'searchSuggestionsUL'
        filteredProducts.forEach(pn => {
          const li = document.createElement('li')
          li.className = 'searchSuggestion dropdown-item'
          li.style = 'cursor: pointer;'
          li.textContent = pn
          li.addEventListener('click', (event) => {
            navbarSearchInput.value = event.target.textContent
          })
          ul.appendChild(li)
        })
        navbarSearchSuggestions.appendChild(ul)
      })
    })
}

function getLocalStoreItem (value) {
  try {
    const res = JSON.parse(value)
    return res
  } catch (err) {
    return value
  }
}

/*
if (window.location.pathname === '/login') {
  const form = document.querySelector('#loginForm')
  if (form) {
    const handleDevice = (e) => {
      e.preventDefault()
      const username = e.target[0].value
      const device = new DeviceInfo()
      device.id = `${username}-${device.os.name}${device.os.version}${device.browser.name}${device.browser.version}${device.resolution}`
      fetch('https://numedica.com/session/add-device', {
        method: 'POST',
        body: JSON.stringify({ username, device }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          form.removeEventListener('submit', handleDevice)
          form.submit()
        })
        .catch(err => {
          console.log(err)
          form.removeEventListener('submit', handleDevice)
          form.submit()
        })
        .finally(() => {
          form.removeEventListener('submit', handleDevice)
          form.submit()
        })
    }
    form.addEventListener('submit', handleDevice)
  }
}
*/
