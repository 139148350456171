
/** @typedef {() => void} Noop */
/** @typedef {() => (void | Noop)} RouteHandler */

class CodeRouter {
  constructor () {
    /** @type {Map<string, Set<RouteHandler>>} */
    this.routes = new Map()
    /** @type {Noop[]} */
    this.cleanupFunctions = []
  }

  normalizePath (path) {
    return path.toLowerCase().replace(/\/+/g, '/').replace(/\/$/, '').replace(/#.*$/, '').replace(/\?.*$/, '')
  }

  /**
   *
   * @param {Route} route
   */
  addRoute (route) {
    if (this.routes.has(route.path)) {
      const existingRoute = this.routes.get(route.path)

      for (const handler of route.handlers) {
        existingRoute.add(handler)
      }
    } else {
      const handlers = new Set(route.handlers)
      this.routes.set(route.path, handlers)
    }
  }

  addRouteHandler (path, handler) {
    const normalizedPath = this.normalizePath(path)
    if (this.routes.has(normalizedPath)) {
      this.routes.get(normalizedPath).add(handler)
    } else {
      this.routes.set(normalizedPath, new Set([handler]))
    }
  }

  removeRoute (path) {
    this.routes.delete(this.normalizePath(path))
  }

  handleRoute (path) {
    console.log(`Handling route: ${this.normalizePath(path)}`)
    for (const cleanupFunction of this.cleanupFunctions) {
      cleanupFunction()
    }

    this.cleanupFunctions = []

    const handlers = this.routes.get(this.normalizePath(path))

    if (handlers) {
      console.log(`handlers: Array(${handlers.size})`)
      for (const handler of handlers) {
        const cleanupFunction = handler()

        if (typeof cleanupFunction === 'function') {
          this.cleanupFunctions.push(cleanupFunction)
        }
      }
    } else {
      console.log('handlers: Array(0)')
    }
  }
}

const NM_CODE_ROUTER = new CodeRouter()

/**
 *
 * @param {string} path
 * @param {RouteHandler} handler
 * @returns {Route}
 */
function Route (path, handler) {
  if (!(this instanceof Route)) {
    return new Route(path, handler)
  }

  this.path = path.replace(/\/+/g, '/').replace(/\/$/, '')
  this.handlers = new Set(typeof handler !== 'undefined' ? [handler] : [])
  NM_CODE_ROUTER.addRoute(this)
}

/**
 *
 * @param {RouteHandler} handler
 * @returns {Route}
 */
Route.prototype.add = function (handler) {
  NM_CODE_ROUTER.addRouteHandler(this.path, handler)
  return this
}

Route.prototype.remove = function () {
  NM_CODE_ROUTER.removeRoute(this.path)
}

window.addEventListener('popstate', () => {
  NM_CODE_ROUTER.handleRoute(window.location.pathname)
})

window.addEventListener('load', () => {
  setTimeout(() => {
    NM_CODE_ROUTER.handleRoute(window.location.pathname)
  }, 2000)
})

// document.readyState === 'interactive' ||

function loadHandler () {
  const interval = setInterval(() => {
    console.log(`document.readyState: ${document.readyState}`)
    if (document.readyState === 'complete') {
      clearInterval(interval)
      NM_CODE_ROUTER.handleRoute(window.location.pathname)
    }
  }, 100)
}

loadHandler()

export { NM_CODE_ROUTER, Route }
